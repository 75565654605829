import React from 'react';
import './card.css';

function Card({number, question, answer, backgroundColor, showAnswer} : {number: Number, question: string, answer: string, backgroundColor: string, showAnswer: boolean}){
    return (
        <div className='card' style={{backgroundColor: backgroundColor}}>
            <div className='card__top'>
                <div className='card__number'>{number.toString()}</div>
                <div className='card__question'>{question}</div>
            </div>
            <div className='card__answer' style={{visibility: showAnswer ? 'visible' : 'hidden'}}>
            {answer.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                <span>{line}</span>
                {index !== answer.split('\n').length - 1 && <br />}
                </React.Fragment>
            ))}
            </div>
        </div>
    );
}

export default Card;