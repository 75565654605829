import React, { ReactChildren } from "react";
import './page.css';

function Page({children}: React.PropsWithChildren){
    return (
        <div className='page'>
            {children}
        </div>
    );
}

export default Page;