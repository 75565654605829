import React from "react";
import { Route, Routes } from "react-router";
import Home from "./pages/home/Home";
import Questions from "./pages/questions/Questions";

function App(){
    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/questions" element={<Questions/>} />
        </Routes>
    );
}

export default App;