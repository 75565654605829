import questionData from '../questions.json';

export type Question = {
    number: number;
    question: string;
    answer: string;
    color: string;
}

type Group = {
    name: string;
    enabled: boolean;
    color: string;
    questions: Question[];
}

class QuestionHandler {
    groups: Group[];

    constructor(){
        this.groups = questionData.groups.map(group => ({name: group.name, color: group.color, enabled: true, questions: group.questions.map((question, index) => ({number: index, question: question.question, answer: question.answer, color: group.color}))}));
    }

    changeGroupEnable(index: number, value: boolean){
        this.groups[index].enabled = value;
    }
}

export default new QuestionHandler();