import React, { useState } from 'react';
import './home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Page from '../../components/page/Page';
import questionHandler from '../../utils/questionHandler';
import ReactSwitch from 'react-switch';
import { Link } from 'react-router-dom';

function Home() {
    const [, setRender] = useState(0);

    function handleChange(index: number, value: boolean){
        questionHandler.changeGroupEnable(index, value);
        setRender(r => r === 100 ? 0 : r+1);
    }

    return (
        <Page>
            <div className='home__wrapper'>
                <Link to="/questions">
                    <div className='home__start'>
                        <FontAwesomeIcon icon={faPlay} fontSize={60} />
                    </div>
                </Link>
                <div className='home__groups'>
                    <div className='home__groups__title'>Grupos</div>
                    <div className='home__groups__selectors'>
                        {
                            questionHandler.groups.map((group, index) => (
                                <div key={group.name} className='home__group__selector' style={{backgroundColor: group.color}}>
                                    <div className='home__group__selector__name'>{`${group.name} (${group.questions.length})`}</div>
                                    <ReactSwitch
                                        className="react-switch"
                                        width={50}
                                        onColor='#00d039'
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        handleDiameter={24}
                                        activeBoxShadow={''}
                                        onChange={c => {handleChange(index, c)}}
                                        checked={group.enabled}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default Home;
