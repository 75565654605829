import React, { useCallback, useEffect, useRef, useState } from "react";
import Page from "../../components/page/Page";
import questionHandler, { Question } from "../../utils/questionHandler";
import Card from "../../components/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowRotateLeft, faArrowTurnDown, faCheck, faCross, faDeleteLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import './questions.css';
import Button from "../../components/button/Button";

function shuffle(array: any[]): any[] {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

export default function Questions(){
    const [questions, setQuestions] = useState<Question[]>([]);
    const index = useRef(0);
    const answerVisible = useRef(false);
    const [_, setRender] = useState(0);
    const [score, setScore] = useState(0);

    function restart(){
        setQuestions(shuffle(questionHandler.groups.filter(group => group.enabled).map(group => group.questions).reduce((acc, array) => acc.concat(array), [])));
        index.current = 0;
        answerVisible.current = false;
        setScore(0);
    }

    function markCorrect(){
        if (answerVisible.current){
            index.current += 1;
            answerVisible.current = false;
            setScore(s => s + 1);
        }
    }

    function markIncorrect(){
        if (answerVisible.current){
            index.current += 1;
            answerVisible.current = false;
            setRender(r => r === 100 ? 0 : r+1);
        }
    }

    function showAnswer(){
        answerVisible.current = true;
        setRender(r => r === 100 ? 0 : r+1);
    }

    const handleKeyPress = useCallback((ev: KeyboardEvent) => {
        switch (ev.key){
            case ' ':
                console.log(answerVisible.current, index.current, questions.length);
                if (!answerVisible.current && index.current < questions.length){
                    answerVisible.current = true;
                }
                break;
            case 'Backspace':
                markIncorrect();
                break;
            case 'Enter':
                markCorrect();
                break;
        }

        setRender(r => r === 100 ? 0 : r+1);
    }, [questions]);

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        restart();
    }, []);

    return (
        <Page>
            {
                questions.length > 0 ?
                <div className='questions__wrapper'>
                    <div className='questions__score'>
                        <span style={{color: '#26de81'}}>{score}</span>
                        <FontAwesomeIcon icon={faCheck} color='#26de81' />
                        <span style={{color: 'white'}}>/</span>
                        <span style={{color: '#fc5c65'}}>{index.current - score}</span>
                        <FontAwesomeIcon icon={faXmark} color='#fc5c65' />
                    </div>
                    {
                        index.current < questions.length ?
                        <Card number={questions[index.current].number + 1} question={questions[index.current].question} answer={questions[index.current].answer} backgroundColor={questions[index.current].color} showAnswer={answerVisible.current} />
                        : null
                    }
                    {
                        index.current === questions.length ?
                        <Button title='Reiniciar' icon={faArrowRotateLeft} backgroundColor='#4b7bec' onClick={restart}/>
                        : (answerVisible.current ?
                        <div style={{display: 'flex', gap: '20px'}}>
                            <Button title='Correcto' icon={faCheck} backgroundColor='#26de81' shortcut={[<FontAwesomeIcon icon={faArrowTurnDown} rotation={90} fontSize={14} />]} onClick={markCorrect} />
                            <Button title='Incorrecto' icon={faXmark} backgroundColor='#fc5c65' shortcut={[<FontAwesomeIcon icon={faDeleteLeft} fontSize={14} />]} onClick={markIncorrect} />
                        </div> :
                        <Button title='Mostrar respuesta' icon={faArrowRight} backgroundColor='#4b7bec' onClick={showAnswer} shortcut={[<span style={{transform: 'rotate(-90deg)'}}>[</span>]}/>)
                    }
                </div>
                : null
            }
        </Page>
    );
}