import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './button.css'

interface ButtonProps {
    title?: string;
    icon?: IconProp | null;
    ghost?: boolean;
    compact?: boolean;
    disabled?: boolean;
    loading?: boolean;
    small?: boolean;
    fontSize?: number;
    color?: string;
    backgroundColor?: string;
    fontColor?: string;
    onClick?: () => void;
    marginBottom?: number;
	shortcut?: JSX.Element[] | null;
}

export default function Button({title = '', icon = null, ghost = false, compact = false, disabled = false, loading = false, small = false, fontSize = 16, color = 'white', backgroundColor = '', fontColor = 'white', onClick = () => {}, marginBottom = 14, shortcut = null}: ButtonProps) {
	const clickHandler = () => { if (!disabled && !loading) onClick() };

	return (
		<div
			className={`
				button__wrapper
				${icon ? 'icon' : ''}
				${ghost ? 'ghost' : ''}
				${compact ? 'compact' : ''}
				${disabled ? 'disabled' : ''}
				${small ? 'small': ''}
			`}
			style={{
				marginBottom: marginBottom,
			}}
			onClick={clickHandler}
		>
			<div
				className='button__interior'
				style={{
					color: ghost ? 'var(--theme-color)' : color,
					backgroundColor: ghost ? 'var(--theme-color-light)' : backgroundColor,
					fontSize: fontSize
				}}
			>
				{icon && !loading ? <FontAwesomeIcon icon={icon} /> : null}
				{title && !loading ? <div className="button__title">{title}</div> : null}
				{
					shortcut ?
					shortcut.map(s => (
						<div className='button__shortcut'>
							{s}
						</div>
					))
					: null
				}
			</div>
		</div>
	)
}